<template>
	<div class="main">
		<template  v-if="dataInfo">
			<div class="info-card-box">
				<div class="card-line-box">
					<span class="card-item-title">市场：</span>
					<span class="card-item-text">{{dataInfo.market}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">部门：</span>
					<span class="card-item-text">{{dataInfo.depName}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">岗位：</span>
					<span class="card-item-text">{{dataInfo.position}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">姓名：</span>
					<span class="card-item-text">{{dataInfo.userName}}</span>
				</div>
			</div>
			<div class="perf-info-box">
				<div class="table-title-box">
					<img style="width: 15px;height: 15px" src="../../assets/image/performance/table.png"/>
					<span class="table-title-text">{{monthArr[0]}}年{{monthArr[1]}}绩效考核表</span>
				</div>
				<div class="table-box">
					<div class="table-title-line">
						<div class="table-cell" style="width: 45vw">指标名称</div>
						<div class="table-cell" style="width: 20vw">权重</div>
						<!--<div class="table-cell" style="width: 40vw">定义及计算方法</div>-->
						<div class="table-cell" style="width: 25vw">目标值</div>
					</div>
					<div @click="toAddInfo(index)" v-for="(item, index) in postData.contents" :key="index" class="table-line">
						<div class="table-cell" style="width: 45vw">{{item.target}}</div>
						<div v-if="item.weight > 0" class="table-cell" style="width: 20vw">{{item.weight}}%</div>
						<div v-else class="table-cell" style="width: 20vw"></div>
						<!--<div class="table-cell" style="width: 40vw">{{item.computeMode}}</div>-->
						<div class="table-cell" style="width: 25vw">{{item.targetValue}}</div>
					</div>
				</div>
				<div v-if="isOverTime === 1" @click="toAdd" class="add-button">
					<span class="add-button-icon">+</span>
					<span class="add-button-text">添加考核项</span>
				</div>
				<div>
					<div class="ticket-title">附件</div>
					<div class="no-text">
						<van-uploader upload-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165994288232878081" v-model="ticket" :max-count="3" :after-read="afterRead" />
					</div>
					<!--upload-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165977734199516424" -->
				</div>
			</div>
			<div class="perf-info-box">
				<div class="order-status-title">审批流程</div>
				<div>
					<van-steps direction="vertical" active-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" inactive-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" active-color="#333333" :active="0">
						<van-step v-for="(item, index) in activities" :key="index">
							<p class="steps-title">{{item.message}}<span v-if="index === 0" class="active-tip">或签</span></p>
							<div class="leader-box">
								<div v-for="(item2,index2) in item.operator" :key="index2" class="leader-item">
									<img class="step-img" src="../../assets/image/performance/r.png"/>
									<span>{{item2}}</span>
								</div>
							</div>
						</van-step>
					</van-steps>
				</div>
			</div>
			<div style="width: 100vw;height: 80px"></div>
			<div v-if="dataInfo.status === -1 || dataInfo.status === 3 || dataInfo.status === 0" class="bottom-button-box">
				<div v-if="isOverTime === 1" @click="addPerfData(0)" class="button-no">暂存</div>
				<div v-if="isOverTime === 1" @click="addPerfData(1)" class="button-ok">提交</div>
			</div>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getUserData, getCosToken, addPerf, editPerf, submitPerf, isDateIn } from '@/api/performance'
	import {Dialog, Toast} from 'vant';
	import COS from 'cos-js-sdk-v5'
	import { Empty } from 'vant';
	// import Cookies from "js-cookie";
	export default {
		name: 'DemandList',
		components: {
			Empty
		},
		data() {
			return {
				isOverTime: 1,
				activities: [
					{
						message:'上级市场老总',
						operator:''
					},
					{
						message:'总部人事',
						operator:''
					},
				], // 流程列表
				dataInfo: {},
				postData: {
					id: '',
					submitType: '',
					modifyType: '',
					images: '',
					months: '',
					status: '',
					contents: [
						{
							id: '',
							assessId: '',
							computeMode: '',
							rater: '',
							raterName: '',
							remarks: '',
							source: '',
							target: '',
							targetValue: '',
							weight: ''
						}
					],
				},
				ticket:[],
				show: false,
				cos: {},
				month: '',
				monthArr: [],
				getStatus: '', // 判断是通过通知进来  还是通过列表进来
			}
		},
		created() {
			const query = this.$route.query
			if (!query.month) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.monthArr = query.month.split("-")
			this.month = query.month
			this.getInfo()
			// this.showApproval()
			this.initCOS()
			// this.isInTime()
		},
		mounted:function(){
		},
		methods: {
			// 判断是否已超时
			isInTime(){
				const postData = {}
				postData.month = this.month
				isDateIn(postData).then(response => {
					console.log(response)
					if(!response){
						this.isOverTime = 2
						Dialog.alert({title: '提示', message: '超时未填写，无法继续填写本月考核任务，请联系总部人事进行补录！',})
					}
				})
			},
			// 获取信息
			getInfo(){
				const postData = {}
				postData.month = this.month
				getUserData(postData).then((res) => {
					this.dataInfo = res.data;
					if(res.data.supLeaderName){
						this.activities[0].operator = res.data.supLeaderName.split(',')
					}
					if(res.data.hrName){
						this.activities[1].operator = res.data.hrName.split(',')
					}
					let tempData = sessionStorage.getItem('prefData')
					if(tempData){
						this.postData = JSON.parse(tempData)
					}else{
						if(res.data.contents){
							this.postData.contents = res.data.contents
						}
						this.postData.id = res.data.id
						this.postData.status = res.data.status
					}
					// 格式化图片链接
					if(this.postData.images !== ''){
						const tempImg = JSON.parse(this.postData.images)
						this.ticket = []
						for(let i = 0; i < tempImg.length; i++){
							let tempUrl = {}
							tempUrl.url = tempImg[i]
							tempUrl.isImage = true
							this.ticket.push(tempUrl)
						}
						console.log(this.ticket)
					}
				})
			},
			// 调到新增页面
			toAdd(){
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.postData.images = JSON.stringify(tempFile)
				}
				sessionStorage.setItem('prefData',JSON.stringify(this.postData))
				const postData = {}
				postData.month = this.month
				this.$router.push({ path: '/addPref', query: postData})
			},
			// 去编辑
			toAddInfo(index){
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.postData.images = JSON.stringify(tempFile)
				}
				sessionStorage.setItem('prefData',JSON.stringify(this.postData))
				const postData = {}
				postData.index = index
				postData.month = this.month
				this.$router.push({ path: '/addPref', query: postData})
			},
			// 暂存或提交数据
			addPerfData(type){
				let _this = this
				this.postData.submitType = type
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.postData.images = JSON.stringify(tempFile)
				}
				this.postData.months = this.month
				if(this.postData.submitType === 0){
					if(this.postData.status === -1){
						addPerf(this.postData).then(response => {
							console.log(response)
							if(response){
								Dialog.alert({title: '提示', message: '操作成功',})
							}
							sessionStorage.removeItem('prefData')
							_this.getInfo()
						})
					}else{
						editPerf(this.postData).then(response => {
							console.log(response)
							if(response){
								Dialog.alert({title: '提示', message: '操作成功',})
							}
							sessionStorage.removeItem('prefData')
							_this.getInfo()
						})
					}
				}else{
					submitPerf(this.postData).then(response => {
						console.log(response)
						if(response){
							Dialog.alert({title: '提示', message: '操作成功',})
						}
						sessionStorage.removeItem('prefData')
						_this.getInfo()
					})
				}
			},
			// 查看流程
			/*showApproval() {
				const postData = {}
				postData.id = this.purchaseNo
				getApproval(postData).then(response => {
					console.log(response)
					this.activities = response.data
				})
			},*/
			// 监听上传
			afterRead(file) {
				console.log(file);
				const typeArr = ['image/png', 'image/jpeg', 'image/jpg']
				const isJPG = typeArr.indexOf(file.file.type) !== -1
				if (!isJPG) {
					return
				}
				// 将文件上传至服务器
				this.toUpload(1, file)
			},
			// 初始化存储桶
			initCOS() {
				// 初始化实例
				const _this = this
				_this.cos = new COS({
					// 必选参数
					getAuthorization: function(options, callback) {
						console.log(222)
						getCosToken({}).then(response => {
							console.log(response)
							if (response.code === 200 || response.code === 0) {
								//  console.log(json)
								const credentials = response.data
								callback({
									TmpSecretId: credentials.tmpSecretId,
									TmpSecretKey: credentials.tmpSecretKey,
									XCosSecurityToken: credentials.sessionToken,
									// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
									StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
									ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900
									// ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
								})
							}
						})
					}
				})
			},
			// 获取随机数
			GetRandomNum(Min,Max) {
				var Range = Max - Min;
				var Rand = Math.random();
				return(Min + Math.round(Rand * Range));
			},
			// 提交上传
			toUpload(type, file) {
				const _this = this
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});
				this.cos.putObject({
					Bucket: 'tousubiaoyang-1301970825', /* 填入您自己的存储桶，必须字段 */
					Region: 'ap-nanjing', /* 存储桶所在地域，例如ap-beijing，必须字段 */
					Key: 'chartVideo/' + new Date().getTime() + this.GetRandomNum(10000,99999), /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
					StorageClass: 'STANDARD',
					Body: file.file, // 上传文件对象
					onProgress: function(progressData) {
						console.log(JSON.stringify(progressData))
					}
				}, function(err, data) {
					Toast.clear()
					console.log(err || data)
					let newUrl = data.Location.replace(/tousubiaoyang-1301970825.cos.ap-nanjing.myqcloud.com/, 'tousubiaoyang-1301970825.file.myqcloud.com')
					newUrl = 'https://' + newUrl
					_this.ticket[_this.ticket.length -1].url = newUrl
					console.log(_this.ticket)
				})
			}
		}
	}
</script>

<style scoped>
	.main{
		color:#333333;
		font-size: 15px;
	}
	.info-card-box{
		background: #FFFFFF;
		padding:15px;
	}
	.perf-info-box{
		margin: 10px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		font-weight: bold;
		font-size: 16px;
	}
	.ticket-title{
		margin-bottom: 10px;
		font-size: 16px;
	}
	.no-text{
		margin-top: 20px;
	}
	.card-line-box{
		display: flex;
		justify-content: flex-start;
		height: 30px;
		line-height: 30px;
	}
	.card-item-title{
		color: #666666 ;
	}
	.card-item-text{
		color: #333333 ;
	}
	.table-title-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 16px;
	}
	.table-title-text{
		padding-left: 5px;
		color: #666666;
		font-size: 16px;
	}
	/*表格开始*/
	.table-box{
		margin: 15px 0;
		border-top: 1px solid #F6F6F6;
		border-left: 1px solid #F6F6F6;
		width: calc(100vw - 30px);
		border-radius: 1px;
	}
	.table-title-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #F1F4FD;
		color: #666666;
		height: 42px;
		line-height: 42px;
		font-size: 14px;
		text-align: center;
	}
	.table-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #000000;
		height: 42px;
		line-height: 42px;
		text-align: center;
	}
	.table-cell{
		border-right: 1px solid #F6F6F6;
		border-bottom: 1px solid #F6F6F6;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow:ellipsis;
		padding: 0 5px;
	}
	/*表格结束*/
	.add-button{
		width: 146px;
		height: 42px;
		background: #5A7DF6;
		border-radius: 4px;

		line-height: 42px;
		color: #ffffff;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
	}
	.add-button-icon{
		font-size: 28px;
		padding-right: 5px;
	}
	.add-button-text{
		font-size: 16px;
	}
	.bottom-button-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		display: flex;
		justify-content: space-around;
		padding: 30px 0;
		background-color: #ffffff;
		font-size: 17px;
		z-index: 999;
	}
	.button-no{
		width: 40vw;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: #F4F6FF;
		border-radius: 4px;
		color: #5A7DF6;
	}
	.button-ok{
		width: 40vw;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: #5A7DF6;
		border-radius: 4px;
		color: #ffffff;
	}
	.steps-title{
		font-size: 16px;
	}
	.leader-box{
		display: flex;
		justify-content: flex-start;
	}
	.leader-item{
		height: 28px;
		line-height: 28px;
		text-align: center;
		background: #F5F6F6;
		border-radius: 2px;
		margin-right: 10px;
		padding: 5px 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.step-img{
		width: 20px;
		height: 20px;
		padding-right: 5px;
	}
	.active-tip{
		color: #5A7DF6;
		border-radius: 2px;
		border: 1px solid #5A7DF6;
		padding: 2px 5px;
		margin-left: 10px;
	}
	/deep/ .van-uploader__upload{
		background-color: #ffffff !important;
	}
	/deep/ .van-uploader__upload-icon {
		font-size: 80px !important;
		display: flex;
		align-items: center;
	}
	/deep/ .van-step--vertical .van-step__circle-container{
		font-size: 20px !important;
	}
	/deep/ .van-step--vertical .van-step__line{
		top: 30px !important;
		height: 75% !important;
		border-left:1px dashed #ebedf0;
		background-color: #ffffff !important;
	}
</style>
